export const DefaultRegionsKey = "currentSelectedRegions";

export class Region {
  availabilityZoneCount?: number;
  displayName: string;
  geography: string;
  latitude?: string;
  longitude?: string;
  pairedRegion?: string;
  physicalLocation?: string;
  regionalDisplayName?: string;
  regionName: string;
  restricted: boolean;
  accessEnabled: boolean;
}

export class RegionModel extends Region {
  averageLatency?: number;
  latestLatency?: number;
  minLatency?: number;
  maxLatency?: number;
  pingCount?: number;
  checked?: boolean;
  showDetails?: boolean;
  bucketName: string;
  url?: string;

  constructor(region: Region) {
    super();
    Object.assign(this, region);
  }
}

export class RegionGroupModel {
  geography: string;
  regions: RegionModel[];
  checked?: boolean;
}

export class HistoryModel {
  [bucketName: string]: {time: number, latency: number}[];
}

export class BlobModel {
  endpoint: string;
  accountName: string;
  containerName: string;
  blobName: string;
  sas: string;
}

export class BlobUploadSpeedModel {
  fileName: any;
  fileSize: string;
  region: string;
  thread: number;
  blockSize: number;
  uploadSpeed: string;
}
