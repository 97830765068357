import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ErrorLogHandlerService {


  constructor(
  ) { }


  public log(err: any): void {
    console.error(new Date().toISOString() + ' <ERROR>: ', err);

  }

}
