import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription, timer } from "rxjs";
import { curveBumpX } from "d3-shape";
import { colorSets, DataItem, MultiSeries } from "@swimlane/ngx-charts";
import { HistoryModel, RegionModel } from "../../models";
import { APIService } from "../../services/api.service";
import { RegionService } from "../../services/region.service";
import {PdfReporterService} from "../../services/pdf-reporter.service";
import {LatencyReportRequest} from "../../models/latency-report-request";
import {NotificationsService} from "../../services/notifications.service";

@Component({
  selector: "app-latency",
  templateUrl: "./latency.component.html",
  styleUrls: ["./latency.component.css"],
})
export class LatencyComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  regions: RegionModel[] = [];

  pingCount = 0;
  updateInterval = 2000;

  automatedTest = false;
  automatedTestReporting = false;
  automatedCount = 0;
  automatedMax = 50;
  reportRequest: LatencyReportRequest;

  closestRegion: string = '';
  furthestRegion: string = '';

  history: HistoryModel = {};
  startTime = new Map<string, number>();
  latestPingTime = new Map<string, number>();
  showDetails = new Map<string, boolean>();
  latestLatency = new Map<string, number>();

  histograms: {[key: string]: {name: string, value: number}[]} = {};

  tableData: RegionModel[] = [];
  tableDataTop: RegionModel[] = [];

  lineChartRawData: any[] = [];

  pingTimer$: Subscription = null;
  chartTimer$: Subscription = null;

  // Line Chart settings
  view: number[] = undefined; // Chart will fit to the parent container size
  lineChartData: MultiSeries = [];
  colorScheme = colorSets.find((s) => s.name === "picnic");
  curve = curveBumpX;
  xAxisTicks: any[] = [];
  pingInterval: any;

  constructor(
    private apiService: APIService,
    private regionService: RegionService,
    private pdfService: PdfReporterService,
    private notifications: NotificationsService
    ) {

  }

  resetStatistics(): void {
    this.history = {};
    this.latestPingTime = new Map<string, number>();
    this.latestLatency = new Map<string, number>();
    this.tableDataTop = [];
    this.tableData = [];
  }

  ngOnInit() {
    const sub = this.regionService.getRegions().subscribe((res) => {
      if (res.length > this.regions.length && !this.pingTimer$) {
        this.pingCount = 0;
        this.regions = res;
      } else {
        this.regions = res;
        this.formatData();
      }
      if (!this.pingTimer$) {
        this.pingTimer();
      }
    });
    this.chartTimer();
    this.subs.push(sub);
  }

  pingTimer() {
    this.pingInterval = setInterval(this.tick.bind(this), 100);
  }

  tick(): void {
    this.pingCount++;
    this.sendHttpPing();
  }

  formatData() {
    const tableDataCache: RegionModel[] = [];
    this.regions.forEach((item, index) => {
      const { regionName, displayName, bucketName, physicalLocation, geography, restricted, accessEnabled } = item;
      const t = this.latestLatency.get(bucketName);
      if (t > 0) {
        let sum = 0;
        let points = 0;
        const hist = this.history[bucketName];
        let min = Infinity;
        let max = -Infinity;
        if (hist) {
          points = hist.length;
          for (const h of hist) {
            sum += h.latency;
            if (h.latency < min) {
              min = h.latency;
            }
            if (h.latency > max) {
              max = h.latency;
            }
          }
        }

        const a = sum / points;
        tableDataCache.push({
          regionName,
          displayName,
          bucketName,
          physicalLocation,
          geography,
          averageLatency: Math.round(a),
          pingCount: this.history[bucketName].length,
          latestLatency: t,
          restricted,
          accessEnabled,
          maxLatency: max,
          minLatency: min,
        });
      }

      if (index === this.regions.length - 1 || !this.tableData.length) {
        this.tableData = tableDataCache;
      }
    });

    this.tableDataTop = [... this.tableData]
      .sort((a, b) => a.latestLatency - b.latestLatency)
    ;
  }

  chartTimer() {
    const xLength = 60;
    this.chartTimer$ = timer(0, 1000).subscribe(() => {
      if (this.regions.length == 0) {
        return;
      }

      const date = new Date();
      const timeStamp = date.getTime() / 1000;
      const second = timeStamp * 1000;
      const secondArr = Array.from({ length: xLength }, (_j, i) => {
        const t = timeStamp - i;
        return t * 1000;
      }).reverse();
      this.tableData.forEach(({ bucketName, displayName }) => {
        let isNew = true;

        this.lineChartRawData.forEach((item: any) => {
          if (bucketName === item.bucketName) {
            isNew = false;
          }
        });
        if (isNew) {
          this.lineChartData.push({
            name: displayName,
            series: secondArr.map((i) => ({
              name: this.formatXAxisTick(i),
              value: 0,
            })),
          });
          this.lineChartRawData.push({
            bucketName,
            name: displayName,
            series: secondArr.map((i) => ({ name: i, value: 0 })),
          });
        }
      });

      this.lineChartRawData.forEach((item: any) => {
        const { bucketName, series } = item;
        const t = this.latestLatency.get(bucketName) || 0;
        let isRemove = true;
        this.tableData.forEach((td) => {
          if (bucketName === td.bucketName) {
            isRemove = false;
          }
        });
        if (series.length > xLength - 1) {
          series.shift();
        }

        series.push({
          name: second,
          value: isRemove ? 0 : t,
        });
      });

      const arr = this.lineChartRawData.map((item: any) => {
        return {
          name: item.name,
          series: item.series.map((seriesItem: DataItem) => ({
            name: this.formatXAxisTick(Number(seriesItem.name)),
            value: seriesItem.value,
          })),
        };
      });
      this.lineChartData = [...arr];

      this.xAxisTicks = this.lineChartRawData[0]
        ? this.lineChartRawData[0].series
            .filter((seriesItem: DataItem) => {
              const timestamp = parseInt(String(seriesItem.name), 10);
              const s = new Date(timestamp).getSeconds();
              return s % 5 === 0;
            })
            .map((seriesItem: DataItem) => this.formatXAxisTick(parseInt(String(seriesItem.name), 10)))
        : [];
    });
  }

  sendHttpPing() {
    let count = 0;
    this.regions.forEach((region) => {
      const {bucketName} = region;
      const last = this.latestPingTime.get(region.bucketName);

      if (last && (Date.now() - last) < this.updateInterval) {
        return;
      }
      count++;
      this.latestPingTime.set(bucketName, Date.now());

      this.startTime.set(bucketName, new Date().getTime());
      const sub = this.apiService.ping(region).subscribe(() => {
        const pingTime = (new Date().getTime() - this.startTime.get(bucketName)).toFixed(0);

        if (!this.history[bucketName]) {
          this.history[bucketName] = [];
        }

        // Drop first ping result as it includes extra DNS time
        // if (this.pingCount >= 2) {
          this.latestLatency.set(bucketName, Number(pingTime));
          this.history[bucketName].push({ time: this.startTime.get(bucketName), latency: Number(pingTime) });

          if (this.history[bucketName].length > 100) {
            this.history[bucketName].shift();
          }
        // }

        this.formatData();
      });
      this.subs.push(sub);
    });
    if (this.automatedTest && count > 0) {
      let min = Infinity;
      let found = false;
      let closest = '';
      let closestLatency = Infinity;
      let furthest = '';
      let furthestLatency = -Infinity;
      for (const r of this.regions) {
        if (this.history[r.bucketName]) {
          if (this.history[r.bucketName].length < min) {
            found = true;
            min = this.history[r.bucketName].length;
          }
          for (const m of this.history[r.bucketName]) {
            if (m.latency < closestLatency) {
              closestLatency = m.latency;
              closest = r.regionName + ' ' + r.displayName + ' (' + r.geography + ')'
            }
            if (m.latency > furthestLatency) {
              furthestLatency = m.latency;
              furthest = r.regionName + ' ' + r.displayName + ' (' + r.geography + ')'
            }
          }
        }
      }

      if (closest != '') {
        this.closestRegion = closest + ' - ' + closestLatency + ' ms';
        this.furthestRegion = furthest + ' - ' + furthestLatency + ' ms';
      }

      if (!found) {
        return;
      }

      this.automatedCount = min;

      if (this.automatedCount == this.automatedMax) {
        this.prepareReport();
        this.regions = [];
      }
    }
  }

  formatXAxisTick(timeStamp: number): string {
    const date = new Date(timeStamp);
    const second = date.getSeconds();
    const h = date.getHours();
    const m = date.getMinutes();
    const hStr = h > 9 ? h : `0${h}`;
    const mStr = m > 9 ? m : `0${m}`;
    return second === 0 ? `${hStr}:${mStr}` : `:${second}`;
  }

  toggleDetails(item: RegionModel) {
    this.showDetails.set(item.regionName, !this.showDetailsChart(item));

    if (this.showDetailsChart(item)) {
      this.histograms[item.bucketName] = this.histogram(this.history[item.bucketName], 10);
    }
  }

  showDetailsChart(item: RegionModel): boolean {
    if (this.showDetails.get(item.regionName)) {
      return true;
    }
    return false;
  }

  histogram(data: { time: number, latency: number }[], interval: number): { name: string, value: number }[] {
    let min = Infinity;
    let max = -Infinity;

    for (const h of data) {
      if (h.latency < min) {
        min = h.latency;
      }
      if (h.latency > max) {
        max = h.latency;
      }
    }

    min = 0;
    if (max < 1000) {
      max = 1000;
    }

    const bins = Math.ceil((max - min) / interval) + 2;

    const values = new Array(bins).fill(0);
    const histogram: { name: string, value: number }[] = [];

    for (const item of data) {
      values[Math.floor((item.latency - min) / interval)]++;
    }

    let start = min;
    for (const v of values) {
      const next = start + interval;
      histogram.push({
        name: start + ' - ' + next, value: (v) ? v : 0
      });
      start = next;
    }

    return histogram;
  }

  async startReport(): Promise<void> {
    const ok = await this.notifications.showQuestion('Start test?', 'Start automated test against all regions?', 'info', 'Start','Cancel');
    if (ok) {
      this.automatedTest = true;
      this.automatedCount = 0;
      this.automatedTestReporting = false;
      this.reportRequest = undefined;

      this.regionService.getAllRegions().subscribe(r => {
        this.resetStatistics();
        this.regions = r;
      });
    }
  }

  prepareReport(): void {
    const req = new LatencyReportRequest();

    req.regions = this.regions;
    req.data = this.history;

    this.reportRequest = req;

    this.fetchReport();
  }

  fetchReport() {
     this.automatedTestReporting = true;
     this.pdfService.generateReport(this.reportRequest).subscribe((pdf) => {
        this.automatedTestReporting = false;
        console.log('Received Response - Saving');
        this.pdfService.saveAs(pdf);
        this.automatedTest = false;
     });
     // this.reportRequest = undefined;
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
    if (this.pingTimer$) {
      this.pingTimer$.unsubscribe();
    }
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    if (this.chartTimer$) {
      this.chartTimer$.unsubscribe();
    }
  }
}
