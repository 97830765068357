<div>
  <div class="text-danger font-weight-bold" *ngIf="totalCheckedRegions == 0">Please select regions to start interactive testing</div>
  <button class="btn btn-outline-primary" (click)="selectAll()">Select all</button>
  <button class="btn btn-outline-primary" style="margin-left: 1rem;" (click)="deSelectAll()">Unselect all</button>
  <div class="row mx-0 mt-2">
    <div class="col-lg-4 pl-0 pr-2 mt-2" *ngFor="let group of regionsGroup">
      <div class="ft-14 border rounded-sm">
        <div class="d-flex justify-content-between bg-light border-bottom p-2">
          <strong>{{ group.geography }}</strong>
          <label [attr.for]="group.geography" class="mb-0">
            <input type="checkbox" [attr.id]="group.geography" [(ngModel)]="group.checked" (change)="onChange(null, group)" />
            Check all
          </label>
        </div>
        <div class="mt-1 px-3 py-2">
          <label
            *ngFor="let region of group?.regions"
            [attr.for]="region.regionName"
            class="cursor-pointer mr-3 mb-1"
            [ngClass]="{ 'text-primary': region.checked }"
          >
            <input type="checkbox" [attr.id]="region.regionName" [(ngModel)]="region.checked" (change)="onChange(region, group)" />
            {{ region.displayName }} ({{ region.regionName }})
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
