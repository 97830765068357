<div>
  <div class="border-bottom">
    <h1 class="h4">AWS Geographies</h1>
    <small>
      A geography is a discrete market, typically containing two or more regions, that preserves data residency and compliance boundaries
    </small>
  </div>
  <div class="mt-3">
    <table class="table table-sm table-bordered table-striped ft-14 mt-2">
      <thead>
        <tr>
          <th>Geography</th>
          <th>Regions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let g of tableData">
          <td>{{ g.Geography }}</td>
          <td>
            <span *ngFor="let r of g.Regions">{{ r.displayName }} ({{ r.regionName }})<br /></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="mt-3">
    <h2 class="h5">Resources</h2>
    <ul>
      <li>
        <a href="https://github.com/blrchen/cloud-data-lab/blob/main/aws/geographies.json" target="_blank">geographies.json</a>
      </li>
    </ul>
  </div>
</div>
