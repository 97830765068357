import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { WaitHelper } from './Wait';

@Injectable()
export class DelayedResponsesInterceptor implements HttpInterceptor {
    constructor(
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(new Promise<HttpEvent<any>>(async (resolve, reject) => {
            try {
                const event = await next.handle(request).toPromise();
                if (event instanceof HttpResponse) {
                    if (event.status === 202) {
                        // response is token with reference to delayed response
                        // make new request for actual response
                        const token = event.body.Token;
                        const binary = event.body.Binary;
                        if (!token) {
                          console.log('No token found in response: ' + JSON.stringify(event, null, 2));
                          resolve(event);
                        } else {
                          const r = await this.waitForResponse(token, binary == true, next);
                          resolve(r);
                        }
                        return;
                    }
                }
                resolve(event);
            } catch (err) {
                reject(err);
            }

        }));
    }

    waitForResponse(token: string, binary: boolean, next: HttpHandler): Promise<HttpEvent<any>> {
        const me = this;
        return new Promise<HttpEvent<any>>(async (resolve, reject) => {
            let req;
            if (binary) {
                const headers = new HttpHeaders({
                    'Accept': 'application/pdf' });
              req = new HttpRequest<Blob>('GET', 'v1/pending-binary-response/unsafe/' + token + '?v=' + new Date().getTime(), {
                responseType: 'blob' as 'json', headers: headers
              } as any);
            } else {
              req = new HttpRequest<any>('GET', 'v1/pending-response/unsafe/' + token + '?v=' + new Date().getTime());
            }
            req = req.clone();

            next.handle(req).subscribe(async (r) => {
                if (r instanceof HttpResponse) {
                    if (r.status === 204) {
                        await WaitHelper.wait(1000);
                        const resp = await me.waitForResponse(token, binary, next);
                        resolve(resp);
                    } else if (r.status === 301) {
                        console.log('Fetching delayed response from: ' + r.headers.get('Location'));
                        req = new HttpRequest<any>('GET', r.headers.get('Location'));
                        next.handle(req).subscribe(async (dr) => {
                            if (dr instanceof HttpResponse) {
                                const filename = dr.headers.get('Location').split('/').reverse()[0];
                              dr = new HttpResponse({
                                headers: dr.headers.set("Content-Disposition", "attachment; filename=\"" + filename +"\""),
                                url: dr.url,
                                status: dr.status,
                                body: dr.body,
                                statusText: dr.statusText
                              });

                              resolve(dr);
                            }
                        });
                    } else {
                      if (r.body.type == 'application/octet-stream') {
                        if (!r.headers.get('Content-Disposition')) {
                          const filename = r.url.split('/').reverse()[0];
                          r = new HttpResponse({
                            headers: r.headers.set("Content-Disposition", "attachment; filename=\"" + filename +"\""),
                            url: r.url,
                            status: r.status,
                            body: r.body,
                            statusText: r.statusText
                          });
                        }
                      }

                        resolve(r);
                    }
                }
            }, (err) => reject(err));
        });
    }
}
