import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-geographies",
  templateUrl: "./geographies.component.html",
  styleUrls: ["./geographies.component.css"],
})
export class GeographiesComponent implements OnInit {
  tableData: any = [];

  ngOnInit() {
    // this.tableData = data;
  }
}
