import {BrowserModule, Title} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgModule, ErrorHandler} from "@angular/core";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {DelayedResponsesInterceptor} from "./services/delayedresponses.interceptor";
import {LoaderComponent} from "./components/loader/loader.component";
import {LayoutComponent} from "./shared/layout/layout.component";
import {NotFoundComponent} from "./shared/not-found/not-found.component";
import {RegionGroupComponent} from "./shared/region-group/region-group.component";
import {GeographiesComponent} from "./aws/geographies/geographies.component";
import {LatencyComponent} from "./aws/latency/latency.component";
import {RegionsComponent} from "./aws/regions/regions.component";
import {APIService} from "./services/api.service";
import {RegionService} from "./services/region.service";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {FormsModule} from "@angular/forms";
import {ErrorInterceptor} from "./helpers/error.interceptor";
import { MegaLoaderComponent } from './components/mega-loader/mega-loader.component';
import {ErrorsHandler} from "./helpers/error-handler";


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    NotFoundComponent,
    RegionGroupComponent,
    GeographiesComponent,
    LatencyComponent,
    RegionsComponent,
    LoaderComponent,
    MegaLoaderComponent],

  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    NgxChartsModule,
    HttpClientModule],

  providers: [
      APIService,
      RegionService,
      Title,
      {provide: ErrorHandler, useClass: ErrorsHandler},
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      {provide: HTTP_INTERCEPTORS, useClass: DelayedResponsesInterceptor, multi: true},
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
}
