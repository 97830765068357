import { Component, OnInit } from "@angular/core";
import { Region } from "src/app/models";
import {RegionService} from "../../services/region.service";

@Component({
  selector: "app-regions",
  templateUrl: "./regions.component.html",
  styleUrls: ["./regions.component.css"],
})
export class RegionsComponent implements OnInit {

  tableData: Region[] = [];

  constructor(
    private regionService: RegionService
  ) {
  }

  ngOnInit() {
    this.regionService.getAllRegions().subscribe(data => {
        this.tableData = data.sort((a, b) => a.geography.localeCompare(b.geography));
    })
  }
}
