import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {LatencyReportRequest} from "../models/latency-report-request";
import {HttpClient, HttpResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PdfReporterService {

  constructor(
    private http: HttpClient
  ) { }

  public generateReport(req: LatencyReportRequest): Observable<HttpResponse<Blob>> {
    return this.http.post<any>('/v1/latency-reports', req,
      { observe: 'response', headers: {
        Accept: 'application/pdf' } });
  }


  public async saveAs(data: HttpResponse<Blob>, filename?: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(data.body);
    const contentDisposition = data.headers.get('Content-Disposition');

    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    if (!filename) {
      filename = 'report.pdf';
    }

    a.download = filename;
    a.click();
    document.body.removeChild(a);
  }

}
