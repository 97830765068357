<div>
  <div class="border-bottom">
    <h1 class="h4">AWS Regions</h1>
    <small>
      A region is a set of datacenters deployed within a latency-defined perimeter and connected through a dedicated regional low-latency
      network.
      <a href="https://aws.amazon.com/about-aws/global-infrastructure/regions_az/" target="_blank">Learn more</a>
    </small>
  </div>

  <div class="mt-3">
    <table class="table table-sm table-bordered table-striped ft-14 mt-2">
      <thead>
        <tr>
          <th>Geography</th>
          <th>Region Name</th>
          <th>Availability Zone #</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let r of tableData">
          <td>{{ r.geography }}</td>
          <td>
            <strong>{{ r.displayName }}</strong>
          </td>
          <td>{{ r.availabilityZoneCount == 0 ? "Not supported" : r.availabilityZoneCount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="mt-3">
    <h2 class="h5">Resources</h2>
    <ul>
      <li>
        <a href="https://github.com/blrchen/cloud-data-lab/blob/main/aws/regions.json" target="_blank">regions.json</a>
      </li>
      <li>
        <a href="https://aws.amazon.com/about-aws/global-infrastructure/regions_az/" target="_blank">AWS regions</a>
      </li>
    </ul>
  </div>
</div>
