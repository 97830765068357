import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mega-loader',
  templateUrl: './mega-loader.component.html',
  styleUrls: ['./mega-loader.component.css']
})
export class MegaLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
