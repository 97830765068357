<div>
  <div class="border-bottom">
    <h1 class="h4">About</h1>
  </div>
  <div>
    <p>
      <a href="https://www.awsspeedtest.com">www.AWSSpeedTest.com</a> - AWS speed test tool. Test your network latency to AWS datacenters
      around the world.
    </p>
    <a href="https://www.azurespeed.com">www.AWSSpeedTest.com</a> is maintained by Blair Chen(<a
      href="https://twitter.com/blrchen"
      target="_blank"
      >@blrchen</a
    >), open source on <a href="https://github.com/blrchen/aws-speed-test" target="_blank">GitHub</a>, built on top of following open source
    projects
    <ul>
      <li>
        <a href="https://github.com/angular/angular" target="_blank">Angular</a>
      </li>
      <li>
        <a href="https://github.com/microsoft/ApplicationInsights-JS" target="_blank">Application Insights JavaScript SDK</a>
      </li>
      <li>
        <a href="https://github.com/dotnet/aspnetcore" target="_blank">ASP.NET Core</a>
      </li>
      <li>
        <a href="https://github.com/blrchen/cloud-data-lab" target="_blank">Cloud Data Lab</a>
      </li>
      <li>
        <a href="https://github.com/twbs/bootstrap" target="_blank">Bootstrap</a>
      </li>
      <li>
        <a href="https://github.com/d3/d3-shape" target="_blank">d3-shape</a>
      </li>
      <li>
        <a href="https://github.com/FortAwesome/Font-Awesome" target="_blank">Font Awesome</a>
      </li>
      <li>
        <a href="https://github.com/swimlane/ngx-charts" target="_blank">ngx-charts</a>
      </li>
      <li>
        <a href="https://github.com/reactivex/rxjs" target="_blank">RxJS</a>
      </li>
    </ul>
  </div>
  <div>
    <h2 class="h5">Feedback</h2>
    <p>
      If you find any bugs or have a feature request, please create an issue to
      <a href="https://github.com/blrchen/aws-speed-test/issues" target="_blank">Github</a>
    </p>
    <h2 class="h5">Contribution</h2>
    <p>
      Contribution is welcome, please fork from
      <a href="https://github.com/blrchen/aws-speed-test" target="_blank">GitHub</a>
      and send pull request
    </p>
    <h2 class="h5">Change logs</h2>
    <h6>2022-2-10</h6>
    <ul>
      <li>Add Geography page</li>
      <li>Add Region</li>
    </ul>
    <h6>2020-11-24</h6>
    <ul>
      <li>Add Latency Test page</li>
    </ul>
  </div>
</div>
