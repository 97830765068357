import {HttpClient} from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DefaultRegionsKey, RegionModel } from "../models";

@Injectable({
  providedIn: "root",
})
export class RegionService {
  private regionSubject = new BehaviorSubject<RegionModel[]>([]);

  constructor(
    private http: HttpClient
  ) {
  }


  updateRegions(regions: RegionModel[]) {
    this.regionSubject.next(regions);
    localStorage.setItem(DefaultRegionsKey, JSON.stringify(regions));
  }

  getRegions(): Observable<RegionModel[]> {
    return this.regionSubject.asObservable();
  }

  getAllRegions(): Observable<RegionModel[]> {
    return this.http.get<RegionModel[]>('regions/regions-data.json?v=' + Date.now());
  }

  clearRegions() {
    this.regionSubject.next(null);
    localStorage.removeItem(DefaultRegionsKey);
  }
}
