import { ErrorHandler, Injectable} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '../services/notifications.service';
import { ErrorLogHandlerService } from '../services/error-log-handler.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {

  public static readonly ReportingBugsEnabled = false;

  constructor(
    private errorService: ErrorLogHandlerService,
    private notifications: NotificationsService,
  ) {}

  async handleError(error: Error) {
    this.errorService.log(error);
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        await this.notifications.showInfo('Computer appears Offline', 'It appears your webbrowser does not have internet access.', 'warning', 'Close', false);
      } else {
        // Handle Http Error (error.status === 403, 404...)
        if (error.status === 401) {
          // ignore
        } else {
          if (ErrorsHandler.ReportingBugsEnabled) {
            const report = await this.notifications.showQuestion('Server Error', 'Backend returned status: ' + error.status + ' - ' + error.message, 'error', 'Report Bug', 'Close');

            if (report) {
              // @ts-ignore
              window['BugBattleReference'].startBugReporting();
            }
          } else {
            await this.notifications.showInfo('Server Error', 'Backend returned status: ' + error.status + ' - ' + error.message, 'error', 'Close', false);
          }
        }
      }
    } else {
      if (ErrorsHandler.ReportingBugsEnabled) {
        const report = await this.notifications.showQuestion('Client Error', error.message, 'error', 'Report Bug', 'Close');

        if (report) {
          // @ts-ignore
          window['BugBattleReference'].startBugReporting();
        }
      } else {
        await this.notifications.showInfo('Client Error', error.message, 'error', 'Close', false);
      }
    }
  }
}
