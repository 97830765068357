import { Injectable } from '@angular/core';

declare const Swal: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private static readonly Title = 'Latency Tester';


  constructor() { }

  public showInfo(title: string, message: string, icon: 'success' | 'warning' | 'info' | 'error',
                  buttonText: string, showCancel: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      console.log(message);
      Swal.fire({
        titleText: title,
        html: message,
        icon: icon,
        footer: '<span>' + NotificationsService.Title + ' - by <a href="https://www.keycore.dk/" target="_blank">KeyCore</a></span>',
        showCloseButton: true,
        showCancelButton: showCancel,
        confirmButtonText: buttonText,
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-secondary btn-margin'
        },
        buttonsStyling: false,
      }).then((res: any) => {
        if (res.value || !showCancel) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  public showQuestion(title: string, message: string,
                      icon: 'success' | 'warning' | 'info' | 'error',
                      confirmText: string, rejectText: string): Promise<boolean> {
    return new Promise((resolve) => {
      console.log(message);
      Swal.fire({
        titleText: title,
        html: message,
        icon: icon,
        footer: '<span>' + NotificationsService.Title + ' - by <a href="https://www.keycore.dk/" target="_blank">KeyCore</a></span>',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: confirmText,
        cancelButtonText: rejectText,
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-secondary btn-margin'
        },
        buttonsStyling: false,
      }).then((res: any) => {
        if (res.value) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  public requestInput(title: string, message: string,
                      icon: 'success' | 'warning' | 'info' | 'error',
                      confirmText: string, rejectText: string): Promise<string> {
    return new Promise<string>((resolve) => {
      console.log(message);
      Swal.fire({
        titleText: title,
        html: message + '<input type="text" id="input" class="swal2-input" placeholder="">',
        icon: icon,
        footer: '<span>' + NotificationsService.Title + ' - by <a href="https://www.keycore.dk/" target="_blank">KeyCore</a></span>',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: confirmText,
        cancelButtonText: rejectText,
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-secondary btn-margin'
        },
        buttonsStyling: false,
        focusConfirm: false,
        preConfirm: () => {
          const inp = Swal.getPopup().querySelector('#input').value;
          if (!inp) {
            Swal.showValidationMessage(`No data entered`);
          } else {
            return inp;
          }
        }
      }).then((res: any) => {
        if (res.value) {
          resolve(res.value);
        } else {
          resolve(undefined);
        }
      });
    });
  }


  public showDateQuestion(title: string, message: string, confirmText: string, rejectText: string): Promise<string> {
    // var flatpickrInstance;
    const n = new Date();
    return new Promise<string>((resolve) => {
      Swal.fire({
        titleText: title,
        html: message + '<input id="report-date" class="swal2-input" value="' + n.toISOString().substring(0, 10) + '" >',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: confirmText,
        stopKeydownPropagation: false,
        cancelButtonText: rejectText,
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-secondary btn-margin'
        },
        buttonsStyling: false,
        focusConfirm: false,
        preConfirm: () => {
          const inp = Swal.getPopup().querySelector('#report-date').value;
          if (!inp) {
            Swal.showValidationMessage(`No data entered`);
          } else {
            return inp;
          }
        },
      }).then((res: any) => {
        if (res.value) {
          resolve(res.value);
        } else {
          resolve(undefined);
        }
      });
    });
  }


  public newPassword( title: string, message: string,
                      icon: 'success' | 'warning' | 'info' | 'error',
                      confirmText: string, rejectText: string): Promise<string> {
    return new Promise<string>((resolve) => {
      console.log(message);
      Swal.fire({
        titleText: title,
        html: message + '<br>Enter Password : <input type="password" id="input" class="swal2-input" placeholder="">'
          + '<br>Repeat Password: <input type="password" id="input2" class="swal2-input" placeholder="">'
        ,
        icon: icon,
        footer: '<span>' + NotificationsService.Title + ' - by <a href="https://www.keycore.dk/" target="_blank">KeyCore</a></span>',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: confirmText,
        cancelButtonText: rejectText,
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-secondary btn-margin'
        },
        buttonsStyling: false,
        focusConfirm: false,
        preConfirm: () => {
          const inp = Swal.getPopup().querySelector('#input').value;
          const inp2 = Swal.getPopup().querySelector('#input2').value;
          if (!inp) {
            Swal.showValidationMessage(`No data entered`);
          } else if (inp !== inp2) {
            Swal.showValidationMessage(`Passwords do not match`);
          } else {
            return inp;
          }
        }
      }).then((res: any) => {
        if (res.value) {
          resolve(res.value);
        } else {
          resolve(undefined);
        }
      });
    });
  }

}
