<div>
  <div class="border-bottom">
    <a href="https://www.keycore.dk" target="_blank">
      <img src="assets/hori_original.svg" alt="Created by KeyCore" style="height: 80px;" />
    </a>
    <h1 class="h4">AWS Region Latency Test</h1>
  </div>
  <div *ngIf="!automatedTest">
    This tool runs latency test from your IP location to AWS regions around the world.
  </div>
  <div class="mt-2">
    <button class="btn btn-outline-primary" *ngIf="!automatedTest" (click)="startReport()">Run fully automated test</button><br>
    <span class="text-small">Fully automated mode will run 50 requests against each region and prepare a PDF report with the results</span>
  </div>
  <div class="mt-2" *ngIf="automatedTest">
     <h4>Running automated latency test</h4>

    <table class="table table-sm table-bordered">
      <tbody>
        <tr>
          <td>Regions being tested</td>
          <td>{{ regions.length }}</td>
        </tr>
        <tr>
          <td>Iterations</td>
          <td>{{ automatedCount }} / {{ automatedMax }}</td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="background-color: #007bff;" [style]="'width: ' + (100*(automatedCount/automatedMax)) + '%'">
              <span class="text-dark text-center" style="width: 100%">{{ ((automatedCount/automatedMax)) | percent }} completed</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>Closest Region</td>
          <td>{{ closestRegion }}</td>
        </tr>
        <tr>
          <td>Furthest Region</td>
          <td>{{ furthestRegion }}</td>
        </tr>
      </tbody>
    </table>
    <app-mega-loader *ngIf="!reportRequest && !automatedTestReporting"></app-mega-loader>
    <button class="btn btn-outline-info" *ngIf="automatedTest && reportRequest && !automatedTestReporting" (click)="fetchReport()">Generate Report</button>
    <h6 *ngIf="automatedTestReporting">Generating Report - may take a minute</h6>
    <app-loader *ngIf="automatedTestReporting"></app-loader>

  </div>
  <div class="mt-2" *ngIf="!automatedTest">
    <app-region-group></app-region-group>
  </div>
  <div class="mt-3" *ngIf="!automatedTest && lineChartRawData.length > 0">
    <div class="text-primary">
      <h5>Closest Regions</h5>
      <button class="btn btn-outline-info" (click)="resetStatistics()">Reset Stats</button>
    </div>
    <table class="table table-sm table-bordered ft-14 mt-2">
      <thead>
        <tr>
          <th scope="col">Region</th>
          <th scope="col">Pings</th>
          <th scope="col">Average Latency (ms)</th>
          <th scope="col">Max Latency (ms)</th>
          <th scope="col">Min Latency (ms)</th>
          <th scope="col">Latest Latency (ms)</th>
        </tr>
      </thead>
      <tbody *ngFor="let item of tableDataTop">
        <tr (click)="toggleDetails(item)" >
          <td >{{ item.regionName }} {{ item.displayName }} ({{ item.geography }})</td>
          <td>{{ item.pingCount }}</td>
          <td style="text-align: right;">{{ item.averageLatency }} ms</td>
          <td style="text-align: right;">{{ item.maxLatency }} ms</td>
          <td style="text-align: right;">{{ item.minLatency }} ms</td>
          <td style="text-align: right;">{{ item.latestLatency }} ms</td>
        </tr>
        <tr *ngIf="showDetailsChart(item)">
          <td colspan="6">
            <div class="mt-2 border bg-light px-2 pt-4">
              <div style="width: 100%; height: 280px">
            <ngx-charts-bar-vertical
              [xAxisLabel]="'Latency'"
              [yAxisLabel]="'Count'"
              [animations]="false"
              [xAxis]="true"
              [yAxis]="true"
              [results]="histograms[item.bucketName]"
              [yScaleMin]="0"
            >
            </ngx-charts-bar-vertical>
              </div>
              </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="mt-4" *ngIf="!automatedTest && lineChartRawData.length > 0">
    <div class="mt-2 border px-2 pt-4">
      <div style="width: 100%; height: 280px">
        <ngx-charts-line-chart
          [results]="lineChartData"
          [scheme]="colorScheme"
          [animations]="false"
          [rangeFillOpacity]="1"
          [xAxis]="true"
          [yAxis]="true"
          [roundDomains]="true"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [curve]="curve"
          [xAxisTicks]="xAxisTicks"
          [yScaleMin]="0"
        >
        </ngx-charts-line-chart>
      </div>
    </div>
  </div>
</div>
