<div class="container-fluid p-0 bd-main">
  <div class="d-flex flex-column flex-md-row flex-xl-nowrap">

<!--    <div class="bd-sidebar">-->

<!--      <nav class="collapse bd-links" id="bd-nav" [ngClass]="{ show: collapse }">-->
<!--        <ul class="nav flex-column bd-sidenav">-->
<!--          <ng-container *ngFor="let item of menus">-->
<!--            <li class="nav-item" routerLinkActive="active">-->
<!--              <a class="nav-link" [routerLink]="item.path" routerLinkActive="active">-->
<!--                <i [class]="item.icon" aria-hidden="true" *ngIf="item.icon"></i>-->
<!--                <span class="ml-2">{{ item.title }}</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </ng-container>-->
<!--        </ul>-->
<!--      </nav>-->
<!--    </div>-->

    <main class="bd-content">
      <div class="py-3 px-xl-5 px-3">
        <ng-content></ng-content>
      </div>
    </main>
  </div>
</div>
