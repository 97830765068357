import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {LatencyComponent} from "./aws/latency/latency.component";
import {GeographiesComponent} from "./aws/geographies/geographies.component";
import {RegionsComponent} from "./aws/regions/regions.component";
import {AboutComponent} from "./aws/about/about.component";

const routes: Routes = [
  {
    path: "latency",
    data: { title: "Aws Latency Test" },
    component: LatencyComponent,
  },
  {
    path: "geographies",
    data: { title: "AWS Geographies" },
    component: GeographiesComponent,
  },
  {
    path: "regions",
    data: { title: "Aws Regions" },
    component: RegionsComponent,
  },
  {
    path: "about",
    data: { title: "About" },
    component: AboutComponent,
  },
  {
    path: "**",
    redirectTo: "latency",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
